const styles = {
  innerContainer: {
    width: "100%",
    marginTop: "24px",
    position: "relative",
    justifyContent: "center"
  }
};

export default styles;
